import { clientConfig } from "./config"
import { colors } from "./theme"

const fontFamily =
  clientConfig.company === "greenster" ? "Lato" : '"DM Sans", sans-serif'

export const CLERK_THEME = {
  layout: {
    logoPlacement: "none",
    socialButtonsPlacement: "bottom",
    termsPageUrl: "https://www.meetkiosk.com/mentions-legales",
    privacyPageUrl: "https://www.meetkiosk.com/politique-de-confidentialite",
  },
  variables: {
    colorPrimary: colors.green[6],
    colorSuccess: colors.green[5],
    colorText: colors.gray[8],
    colorTextSecondary: colors.gray[6],
    colorInputText: colors.gray[8],
    fontFamily,
  },
} as const
