import { captureRemixErrorBoundaryError } from "@sentry/remix"
import "@mantine/core/styles.css"
import "@mantine/charts/styles.css"
import {
  Links,
  Meta,
  Outlet,
  redirect,
  Scripts,
  ScrollRestoration,
  useNavigate,
  useRouteError,
} from "@remix-run/react"
import type {
  LinksFunction,
  LoaderFunctionArgs,
  MetaFunction,
} from "@remix-run/node"
import { ColorSchemeScript, MantineProvider } from "@mantine/core"
import { theme } from "./theme"
import { useTranslation } from "react-i18next"

import { rootAuthLoader } from "@clerk/remix/ssr.server"
import { ClerkApp } from "@clerk/remix"
import { CLERK_THEME } from "./clerk-theme"
import { routes } from "./utils/constants/routes"
import { Error } from "@kiosk/audit/components/Error/Error"

export const links: LinksFunction = () => [
  { rel: "preconnect", href: "https://fonts.googleapis.com" },
  {
    rel: "preconnect",
    href: "https://fonts.gstatic.com",
    crossOrigin: "anonymous",
  },
  {
    rel: "stylesheet",
    href: "https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap",
  },
]

export const meta: MetaFunction = () => {
  return [
    { title: "Kiosk Audit" },
    { name: "description", content: "The CSRD audit app by Kiosk" },
  ]
}

export const handle = {
  i18n: "common",
}

export async function loader(args: LoaderFunctionArgs) {
  return rootAuthLoader(args, async ({ request }) => {
    const { userId } = request.auth

    const url = new URL(request.url)
    const pathname = url.pathname

    if (!userId && pathname !== routes.SIGN_IN.path) {
      return redirect(routes.SIGN_IN.path)
    }
    return null
  })
}

export function Layout({ children }: { children: React.ReactNode }) {
  const locale = "fr"
  const { i18n } = useTranslation()
  return (
    <html lang={locale} dir={i18n.dir()}>
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
        <ColorSchemeScript />
      </head>
      <body>
        <MantineProvider theme={theme}>{children}</MantineProvider>
        <ScrollRestoration getKey={(location) => location.pathname} />
        <Scripts />
      </body>
    </html>
  )
}

export const ErrorBoundary = () => {
  const { t } = useTranslation("error")
  const error = useRouteError()
  const navigate = useNavigate()
  captureRemixErrorBoundaryError(error)
  return (
    <Error
      message={t("unknown.message")}
      action={() => navigate(-1)}
      actionLabel={t("unknown.action")}
    />
  )
}

function App() {
  return <Outlet />
}

export default ClerkApp(App, {
  appearance: CLERK_THEME,
})
