import { Center, Stack, Text } from "@mantine/core"
import { Button } from "@kiosk/audit/components/Button/Button"
import { Logo } from "@kiosk/audit/components/Logo/Logo"
import classes from "./Error.module.css"

type ErrorProps = {
  message: string
  action: () => void
  actionLabel: string
}

export const Error = ({ message, actionLabel, action }: ErrorProps) => {
  return (
    <Center className={classes.error}>
      <Stack>
        <Logo height={28} />
        <Text className={classes.error__message}>{message}</Text>
        <Center>
          <Button onClick={action}>{actionLabel}</Button>
        </Center>
      </Stack>
    </Center>
  )
}
